import { FC } from 'react';
import styled from 'styled-components';
import { ThemeConstants, KitFlexRowSpaced } from '@chargepoint/cp-toolkit';
import Duration from '../DataFromat/Duration';
import Currency from '../DataFromat/Currency';
import ExpiresOn from '../DataFromat/ExpiresOn';
import Distance from '../DataFromat/Distance';
import { TranslateFunc } from '../../types';

// eslint-disable-next-line no-shadow
enum RedeemType {
  TIME,
  MONEY,
  KWH,
  RANGE,
}

export interface PromoDetailsProps {
  name: string;
  description?: string;
  expires: Date;
  amount: number;
  expiresSoon?: boolean;
  redeemType: RedeemType;
  currency?: string;
  className?: string;
  t: TranslateFunc;
  locale: string;
}

const Container = styled.div``;

const Range = styled.div`
  font-size: ${ThemeConstants.fontSize.p}rem;
  line-height: unset;
  color: ${({ theme }) => theme.page.body.text};
`;

const NameContainer = styled(KitFlexRowSpaced)`
  font-size: ${ThemeConstants.fontSize.p}rem;
  line-height: unset;
  color: ${({ theme }) => theme.page.body.text};
  gap: ${ThemeConstants.spacing.absolute.xs}px;
  flex-wrap: wrap;
`;

const PromoName = styled(Range)`
  font-weight: bold;
  margin: 0;
`;

const PromoDesc = styled(Range)`
  margin: ${ThemeConstants.spacing.absolute.xs}px 0 0 0;
`;

const PromoDetails: FC<PromoDetailsProps> = ({
  name,
  amount,
  currency,
  redeemType,
  description,
  expires,
  expiresSoon,
  className,
  t,
  locale,
}: PromoDetailsProps) => {
  function renderPromo() {
    switch (redeemType) {
      case RedeemType.MONEY: {
        return <Currency amount={amount} currencyCode={currency || 'USD'} locale={locale} />;
      }
      case RedeemType.TIME: {
        return <Duration timestamp={amount} t={t} locale={locale} />;
      }
      case RedeemType.KWH: {
        const kWh = Intl.NumberFormat(locale, { maximumFractionDigits: 2 }).format(amount);
        return <Range>{t('web_common:range_kWh', { formattedCount: kWh })}</Range>;
      }
      case RedeemType.RANGE: {
        return <Distance miles={amount} t={t} locale={locale} />;
      }
      default:
        return null;
    }
  }

  return (
    <Container className={className}>
      <NameContainer>
        <PromoName>{name}</PromoName>
        {renderPromo()}
      </NameContainer>
      {description && <PromoDesc>{description}</PromoDesc>}
      <ExpiresOn date={expires} expiresSoon={expiresSoon} t={t} locale={locale} />
    </Container>
  );
};

export default PromoDetails;
