import styled from 'styled-components';
import { ThemeConstants } from '@chargepoint/cp-toolkit';

const Paragraph = styled.p`
  font-size: ${ThemeConstants.fontSize.text_12}rem;
  padding-bottom: ${ThemeConstants.spacing.m}rem;
  margin-bottom: 0;
  line-height: 1.2;
  /* this adds a margin to the top of the paragraph that is appropriate to the line-height, since everything else is 1. */
  &::before {
    content: '';
    display: block;
    height: 0;
    width: 0;
    margin-top: calc((1 - 1.2) * 0.5em);
  }
  & a {
    font-size: ${ThemeConstants.fontSize.text_12}rem;
  }
`;

export default Paragraph;
