import { FC } from 'react';
import { ThemeConstants } from '@chargepoint/cp-toolkit';
import styled from 'styled-components';
import PromoDetails, { PromoDetailsProps } from './PromoDetails';

export interface PromoListProps {
  promos: Array<PromoDetailsProps>;
  className?: string;
}

const Container = styled.div`
  @media (min-width: ${ThemeConstants.breakpoints.sm}px) {
    padding: ${ThemeConstants.spacing.absolute.m}px;
  }
`;

const Separator = styled.hr`
  margin: ${ThemeConstants.spacing.absolute.m}px -${ThemeConstants.spacing.absolute.s}px;
`;

const PromoList: FC<PromoListProps> = ({ promos, className }: PromoListProps) => {
  return (
    <Container className={className}>
      {promos.map((promo, index) => {
        return (
          <>
            {index > 0 && <Separator />}
            <PromoDetails {...promo} />
          </>
        );
      })}
    </Container>
  );
};

export default PromoList;
