import { FC } from 'react';
import styled from 'styled-components';
import { ThemeConstants } from '@chargepoint/cp-toolkit';
import { TranslateFunc } from '../../types';

export interface DurationProps {
  timestamp: number;
  className?: string;
  t: TranslateFunc;
  locale: string;
}

const Container = styled.span`
  white-space: nowrap;
  font-size: ${ThemeConstants.fontSize.p}rem;
  line-height: unset;
  color: ${({ theme }) => theme.page.body.text};
`;

const Duration: FC<DurationProps> = ({ timestamp, className, t, locale }: DurationProps) => {
  const hours = Math.floor(timestamp / 3600);
  const minutes = Math.floor((timestamp - hours * 3600) / 60);
  const seconds = timestamp - hours * 3600 - minutes * 60;
  const parts = [];

  if (hours) {
    parts.push(
      t('web_common:duration.hours', {
        count: hours,
        formattedCount: Intl.NumberFormat(locale).format(hours),
      })
    );
  }

  if (minutes) {
    parts.push(t('web_common:duration.minutes', { count: minutes }));
  }

  if (seconds || parts.length === 0) {
    parts.push(t('web_common:duration.seconds', { count: seconds }));
  }

  return <Container className={className}>{parts.join(' ')}</Container>;
};

export default Duration;
