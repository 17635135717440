import { FC } from 'react';
import styled from 'styled-components';
import { ThemeConstants, KitFlexRowSpaced } from '@chargepoint/cp-toolkit';
import { TranslateFunc } from '../../types';

export interface ExpiresOnProps {
  date: Date;
  expiresSoon?: boolean;
  className?: string;
  t: TranslateFunc;
  locale: string;
}

const Container = styled(KitFlexRowSpaced)<ExpiresOnProps>`
  margin: ${ThemeConstants.spacing.absolute.sm}px 0 0 0;
  font-size: ${ThemeConstants.fontSize.p}rem;
  line-height: unset;
  color: ${({ expiresSoon, theme }) => (expiresSoon ? theme.error : theme.page.body.text)};
  gap: ${ThemeConstants.spacing.absolute.xs}px;
  flex-wrap: wrap;
`;

const DateContainer = styled.div`
  margin: 0;
`;

const ExpiresOn: FC<ExpiresOnProps> = ({
  date,
  expiresSoon,
  className,
  t,
  locale,
}: ExpiresOnProps) => {
  const expiryDate = new Intl.DateTimeFormat(locale, {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
  }).format(date);
  const timeRemaining = date.getTime() - new Date().getTime();
  const secondsRemaining = Math.round(timeRemaining / 1000);
  const minuitesRemaining = Math.round(timeRemaining / (1000 * 60));
  const hoursRemaining = Math.round(timeRemaining / (1000 * 60 * 60));
  const daysRemaining = Math.round(timeRemaining / (1000 * 60 * 60 * 24));

  function getRemainingTime(): string {
    if (daysRemaining) {
      return t('web_common:remaining_time.days', {
        count: daysRemaining,
        formattedCount: Intl.NumberFormat(locale).format(daysRemaining),
      });
    }
    if (hoursRemaining) {
      return t('web_common:remaining_time.hours', { count: hoursRemaining });
    }
    if (minuitesRemaining) {
      return t('web_common:remaining_time.minutes', { count: minuitesRemaining });
    }
    return t('web_common:remaining_time.seconds', { count: secondsRemaining });
  }

  return (
    <Container {...{ date, expiresSoon, className, t, locale }}>
      {secondsRemaining > 0 ? (
        <>
          <DateContainer>{t('web_common:expires_on', { date: expiryDate })}</DateContainer>
          <DateContainer>{getRemainingTime()}</DateContainer>
        </>
      ) : (
        <DateContainer>{t('web_common:expired')}</DateContainer>
      )}
    </Container>
  );
};

export default ExpiresOn;
