import { TranslateFunc } from '../../types';

export interface ConvertedTotalParams {
  driverCurrency: string;
  stationCurrency: string;
  conversionRate: number;
  t: TranslateFunc;
}

export function getConvertedTotalLabel(convertedTotalParams: ConvertedTotalParams): string {
  const { t, driverCurrency, stationCurrency, conversionRate } = convertedTotalParams;
  return t('web_common:receipt.converted_total', {
    driverCurrency,
    stationCurrency,
    conversionRate,
  });
}

export const formatkWh = (
  t: TranslateFunc,
  locale: string,
  includeUnits: boolean,
  energy?: number
): string => {
  if (!energy) {
    return t('web_common:receipt.meter_range_unavailable');
  }
  const kWh = Intl.NumberFormat(locale, { maximumFractionDigits: 4 }).format(energy);
  return includeUnits ? t('web_common:range_kWh', { formattedCount: kWh }) : kWh;
};
