import { FC } from 'react';
import styled from 'styled-components';
import { ThemeConstants, KitFlexRow } from '@chargepoint/cp-toolkit';
import { TranslateFunc } from '../../types';

export interface DistanceProps {
  miles: number;
  precision?: number;
  className?: string;
  t: TranslateFunc;
  locale: string;
}

export const MILES_TO_KMS = 1.60934;

const Container = styled(KitFlexRow)`
  font-size: ${ThemeConstants.fontSize.p}rem;
  line-height: unset;
  flex-wrap: wrap;
  gap: ${ThemeConstants.spacing.absolute.xs}px;
  color: ${({ theme }) => theme.page.body.text};
`;

const Distance: FC<DistanceProps> = ({
  miles,
  precision = 0,
  className,
  t,
  locale,
}: DistanceProps) => {
  const kms = miles * MILES_TO_KMS;
  const options = { maximumFractionDigits: precision };

  return (
    <Container className={className}>
      <span>
        {t('web_common:distance_mi', {
          count: miles,
          formattedCount: Intl.NumberFormat(locale, options).format(miles),
        })}
      </span>
      <span>/</span>
      <span>
        {t('web_common:distance_kms', {
          count: kms,
          formattedCount: Intl.NumberFormat(locale, options).format(kms),
        })}
      </span>
    </Container>
  );
};

export default Distance;
