import { FC, useRef } from 'react';
import { KitModal, KitButton, KitLink, KitButtonBar } from '@chargepoint/cp-toolkit';
import styled from 'styled-components';
import { UserAgreement } from '../services/discovery/interfaces';
import { TranslateFunc } from '../types';

export interface UserAgreementProps {
  t: TranslateFunc;
  agreements: UserAgreement[];
  optInByDefault: boolean;
  onDone: (allAgreed: boolean) => void;
}

const StyledLi = styled.li`
  list-style-type: disc;
  list-style-position: inside;
`;

export const UserAgreementsDialog: FC<UserAgreementProps> = ({
  t,
  agreements,
  optInByDefault,
  onDone,
}: UserAgreementProps) => {
  const okButtonRef = useRef<HTMLButtonElement>(null);

  function focusOnOK() {
    if (okButtonRef && okButtonRef.current) {
      okButtonRef.current.focus();
    }
  }

  return (
    <KitModal show position='center' closeOnClickOutside={false} onInitComplete={focusOnOK}>
      <KitModal.Header t={t} closeButton={false}>
        <KitModal.Title>{t('web_common:user_agreements.title')}</KitModal.Title>
      </KitModal.Header>
      <KitModal.Body>
        <p>
          {t(
            optInByDefault
              ? 'web_common:user_agreements.helptext_review'
              : 'web_common:user_agreements.helptext_review_and_accept'
          )}
        </p>
        <ul>
          {agreements &&
            agreements.map((agreement) => {
              return (
                <StyledLi key={agreement.name}>
                  <KitLink onClick={() => window.open(agreement.url)}>
                    {agreement.localizedName}
                  </KitLink>
                </StyledLi>
              );
            })}
        </ul>
      </KitModal.Body>
      <KitModal.Footer>
        <KitButtonBar
          primary={
            <KitButton onClick={() => onDone(true)} ref={okButtonRef}>
              {optInByDefault
                ? t('web_common:user_agreements.button_ok')
                : t('web_common:user_agreements.button_accept')}
            </KitButton>
          }
          secondary={
            optInByDefault ? (
              <></>
            ) : (
              <KitButton variant='secondary' onClick={() => onDone(false)}>
                {t('web_common:user_agreements.button_deny')}
              </KitButton>
            )
          }
        />
      </KitModal.Footer>
    </KitModal>
  );
};
