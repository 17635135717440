import { FC } from 'react';
import styled from 'styled-components';
import { ThemeConstants } from '@chargepoint/cp-toolkit';

export interface CurrencyProps {
  amount: number;
  currencyCode: string;
  currencyDisplay?: 'symbol' | 'code' | 'name';
  className?: string;
  locale: string;
}

const Container = styled.span`
  white-space: nowrap;
  font-size: ${ThemeConstants.fontSize.p}rem;
  line-height: unset;
  color: ${({ theme }) => theme.page.body.text};
`;

const Currency: FC<CurrencyProps> = ({
  amount,
  currencyCode,
  currencyDisplay = 'symbol',
  className,
  locale,
}: CurrencyProps) => {
  const formattedNumber = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currencyCode,
    currencyDisplay,
  }).format(amount);

  return <Container className={className}>{formattedNumber}</Container>;
};

export default Currency;
