/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable camelcase */
import { FC, MouseEvent } from 'react';
import Paragraph from './ReceiptParagraph';
import { TransFunc, TranslateFunc } from '../../types';

interface PTBEnabledStation {
  authentication_id: string;
  meter_serial_number: string;
  readings: number;
  eichrechtSwUrl?: string;
  vehicle_charge_id: string;
  device_id: number;
}

interface PTBProps {
  // eslint-disable-next-line no-use-before-define
  station: PTBEnabledStation;
  t: TranslateFunc;
  nosServerEndpoint: string;
  accountEndPoint: string;
  corporateFAQUrl: string;
  Trans: TransFunc;
  isLoggedIn: boolean;
}

const PTBFooter: FC<PTBProps> = ({
  station,
  t,
  corporateFAQUrl,
  nosServerEndpoint,
  accountEndPoint,
  Trans,
  isLoggedIn,
}: PTBProps) => {
  function downloadMeteringDatasetAnon() {
    const url = `${accountEndPoint}receipt/anonymous/downloader/${station.vehicle_charge_id}`;

    // Fetch it to see if it's there, then go to that URL
    fetch(url, {
      method: 'GET',
      credentials: 'include',
    })
      .then((response) => {
        if (response.ok) {
          window.location.href = url;
        } else {
          throw new Error();
        }
      })
      .catch(() => {
        // eslint-disable-next-line no-alert
        alert(t('web_common:receipt.download_error'));
      });
  }

  function downloadMeteringDatasetRegistered() {
    const formData = new FormData();
    formData.append('vc_id', station.vehicle_charge_id);
    formData.append('device_id', station.device_id.toString());
    fetch(`${nosServerEndpoint}report/is_receipt_exists`, {
      method: 'POST',
      body: formData,
      credentials: 'include',
    })
      .then((response) => response.json())
      .then((data) => {
        if (typeof data.file_exists !== 'undefined' && data.file_exists === true) {
          window.location.href = `${nosServerEndpoint}report/fetch_aws_sess_receipt_blob/${station.device_id}/${station.vehicle_charge_id}`;
        } else {
          throw new Error();
        }
      })
      .catch(() => {
        // eslint-disable-next-line no-alert
        alert(t('web_common:receipt.download_error'));
      });
  }

  function downloadMeteringDataset(e: MouseEvent<HTMLAnchorElement>) {
    if (isLoggedIn) {
      downloadMeteringDatasetRegistered();
    } else {
      downloadMeteringDatasetAnon();
    }

    e.preventDefault();
    e.stopPropagation();
  }

  function getTransperancySWAppLink() {
    // eslint-disable-next-line no-use-before-define
    return station.eichrechtSwUrl ? station.eichrechtSwUrl : getDefaultSwAppLink();
  }

  function getDefaultSwAppLink() {
    // eslint-disable-next-line no-use-before-define
    const os = getOperatingSystem();
    switch (os) {
      case 'WindowsOS':
        return 'https://owncloud.graphdefined.com/index.php/s/N98qCzdW5rk6Skv';
      case 'MacOS':
        return 'https://owncloud.graphdefined.com/index.php/s/HGGKrySOjpdUEUZ';
      default:
        return 'https://owncloud.graphdefined.com/index.php/s/neSPpgtSGfe75VO';
    }
  }

  function getOperatingSystem() {
    let operatingSystem = 'Not known';
    if (window.navigator.appVersion.indexOf('Win') !== -1) {
      operatingSystem = 'WindowsOS';
    }
    if (window.navigator.appVersion.indexOf('Mac') !== -1) {
      operatingSystem = 'MacOS';
    }
    if (window.navigator.appVersion.indexOf('X11') !== -1) {
      operatingSystem = 'UNIXOS';
    }
    if (window.navigator.appVersion.indexOf('Linux') !== -1) {
      operatingSystem = 'LinuxOS';
    }

    return operatingSystem;
  }

  return (
    <Paragraph>
      <Trans i18nKey='web_common:receipt.ptb_receipt_verification'>
        {/* eslint-disable-next-line prettier/prettier */}
        If you
        <a href='#' onClick={downloadMeteringDataset}>
          download the measurement data record
        </a>
        and check it with the <a href={getTransperancySWAppLink()}>Transperancy SW App</a>, you can
        check the correctness and authenticity of your receipt. Get instructions
        <a href={corporateFAQUrl}>here</a>.
      </Trans>
    </Paragraph>
  );
};

export default PTBFooter;
