/* eslint-disable no-prototype-builtins */
/* eslint-disable camelcase */
/* eslint-disable no-use-before-define */
import { FC, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import styled from 'styled-components';
import {
  ThemeConstants as constants,
  ThemeColors as colors,
  KitButton,
  KitLink,
} from '@chargepoint/cp-toolkit';
import {
  ReceiptData,
  LineItemDetails,
  Energy,
  Parking,
  FlatFee,
  Tax,
  Minadj,
  Maxadj,
  ChargeInterruption,
  Payment,
  EMVData,
} from './ReceiptInterfaces';
import PTBFooter from './PTBFooter';
import { TransFunc, TranslateFunc } from '../../types';
import { formatkWh, getConvertedTotalLabel } from './ReceiptUtils';

const LineItemSection = styled.div`
  margin-bottom: ${constants.spacing.m}rem;
`;

const LineItemRow = styled.div`
  font-size: ${constants.fontSize.p}rem;
  color: ${({ theme }) => theme.page.body.text};
  line-height: normal;
  width: 100%;
  flex: 1;
`;

const SectionContainer = styled.div`
  margin-bottom: ${constants.spacing.l}rem;
  font-size: ${constants.fontSize.p}rem;
`;

const SpacedBetweenSectionContainer = styled(SectionContainer)`
  display: flex;
  justify-content: space-between;
`;

const SpacedBetweenDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;

const FlexEndDiv = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const RightAlignDiv = styled.div`
  text-align: right;
`;

const StyledHr = styled.hr`
  border: 0.5px solid ${colors.gray_40};
`;

const TaxValue = styled(RightAlignDiv)`
  min-width: 5rem;
`;

const SectionTitle = styled.div`
  font-weight: 600;
  color: ${({ theme }) => theme.page.body.text};
  font-size: ${constants.fontSize.p}rem;
  line-height: normal;
`;

const StationCity = styled(SectionTitle)`
  font-weight: normal;
  margin-left: ${constants.spacing.s}rem;
`;

const Text12 = styled.div`
  font-size: ${constants.fontSize.text_12}rem;
`;

const Text12Flex = styled(Text12)`
  margin-bottom: ${constants.spacing.xs}rem;
  flex: 1;
`;

const Text14 = styled.div`
  font-size: ${constants.fontSize.text_14}rem;
  color: ${({ theme }) => theme.page.body.text};
  line-height: normal;
`;

const Text14NoWrap = styled(Text14)`
  white-space: nowrap;
`;

const TextRichBlack = styled.div`
  color: ${({ theme }) => theme.page.body.text};
  font-weight: 300;
  font-size: ${constants.fontSize.text_14}rem;
  letter-spacing: 0.46px;
`;

const TextCenter = styled.div`
  text-align: center;
`;

const AddressDiv = styled.div`
  margin-bottom: ${constants.spacing.l}rem;
  margin-right: ${constants.spacing.s}rem;
`;

const PrintSection = styled.div`
  max-width: 350px;
  @media print {
    margin: 2rem;
    body {
      background-color: #fff;
      padding: 40px !important;
    }
    button {
      display: none !important;
    }
  }
`;

const Paragraph = styled.p`
  font-size: ${constants.fontSize.text_12}rem;
  padding-bottom: ${constants.spacing.m}rem;
  margin-bottom: 0;
  margin-top: 0;
  line-height: normal;

  &::before {
    content: '';
    display: block;
    height: 0;
    width: 0;
    margin-top: calc((1 - 1.2) * 0.5em);
  }

  & a {
    font-size: ${constants.fontSize.text_12}rem;
  }
`;

const FailedTransactionParagraph = styled(Paragraph)`
  color: ${({ theme }) => theme.error};
  text-align: right;
`;

const Paragraph14 = styled(Paragraph)`
  font-size: ${constants.fontSize.text_14}rem;
`;

const Paragraph14Upper = styled(Paragraph)`
  text-transform: uppercase;
`;

const Paragraph16 = styled(Paragraph)`
  font-size: ${constants.fontSize.p}rem;
  padding-bottom: ${constants.spacing.s}rem;
  font-weight: 600;
`;

const PrintButtonContainer = styled.div`
  text-align: right;
`;

const OverstayText12 = styled(Text12)`
  margin-bottom: ${constants.spacing.xs}rem;
  margin-top: ${constants.spacing.s}rem;
  max-width: 85%;
`;

const POWER_TYPE: Record<string, string> = {
  AC_1_PHASE: 'AC',
  AC_3_PHASE: 'AC',
  DC: 'DC',
};

export interface ReceiptDetailsProps {
  className?: string;
  chargingSessionData?: ReceiptData;
  emvData: EMVData | null;
  t: TranslateFunc;
  hideButtons?: boolean | false;
  hidePrint?: boolean | false;
  isDriver?: boolean;
  corpSupportUrl: string;
  corporateFAQUrl: string;
  nosServerEndpoint: string;
  accountEndPoint: string;
  locale: string;
  Trans: TransFunc;
  printButtonVariant?: 'primary' | 'secondary';
  isLoggedIn: boolean;
}

const ReceiptDetails: FC<ReceiptDetailsProps> = ({
  chargingSessionData,
  emvData,
  t,
  isDriver = true,
  corpSupportUrl,
  corporateFAQUrl,
  hideButtons,
  hidePrint,
  nosServerEndpoint,
  accountEndPoint,
  className,
  locale,
  Trans,
  printButtonVariant = 'primary',
  isLoggedIn,
}: ReceiptDetailsProps) => {
  const address = isDriver ? getAddress() : null;
  const printRef = useRef(null);
  const printReceipt = useReactToPrint({
    content: () => printRef.current,
  });
  if (!chargingSessionData) {
    return null;
  }
  const currencyCode = chargingSessionData.payment.org_currency;
  const stationTimeOffset = Number(chargingSessionData.start_offset);
  const billingTime = calcBillingTime(
    chargingSessionData.session_end_epoch_time,
    chargingSessionData.session_start_epoch_time
  );
  const totalEnergy = calcTotalEnergy();
  const meter_range = calcMeterRange();

  if (!chargingSessionData) {
    return null;
  }

  const getEnergyLineItem = (energy_item: Energy): string => {
    // units might be a number for kWh or time in the form 5m 30s...
    const energy_amount = energy_item.energy_unit?.length
      ? localizeDecimal(energy_item.units.toString())
      : energy_item.units;

    if (energy_item.unit_price) {
      return t('web_common:receipt.energy_at_price', {
        energy_amount,
        energy_unit: energy_item.energy_unit,
        cost: addCurrencySymbol(energy_item.unit_price, 5),
        cost_unit: energy_item.unit_price_unit,
        interpolation: { escapeValue: false },
      });
    }
    return t('web_common:receipt.energy_with_unit', {
      energy_amount,
      energy_unit: energy_item.energy_unit,
      interpolation: { escapeValue: false },
    });
  };

  const { isHome } = chargingSessionData;

  function renderLineItemDetails(
    line_item_details: LineItemDetails,
    charge_interruptions: Array<ChargeInterruption>,
    is_payment_failed: boolean | undefined,
    payment: Payment
  ) {
    const line_item_sections = [];

    if (line_item_details.hasOwnProperty('energy')) {
      if (line_item_details.energy) {
        if (line_item_details.energy.length > 0) {
          line_item_sections.push(renderEnergy(line_item_details.energy));
        }
      }
    }

    if (charge_interruptions.length > 0) {
      line_item_sections.push(renderPowerInterruption(charge_interruptions));
    }

    if (line_item_details.hasOwnProperty('parking')) {
      if (line_item_details.parking) {
        if (line_item_details.parking.length > 0) {
          line_item_sections.push(renderParking(line_item_details.parking));
        }
      }
    }

    if (line_item_details.hasOwnProperty('flat_fee') && line_item_details.flat_fee) {
      line_item_sections.push(renderFlatFees(line_item_details.flat_fee));
    }

    if (line_item_details.hasOwnProperty('max_adj') && line_item_details.max_adj) {
      line_item_sections.push(renderMaxFees(line_item_details.max_adj));
    }

    if (line_item_details.hasOwnProperty('min_adj') && line_item_details.min_adj) {
      line_item_sections.push(renderMinFees(line_item_details.min_adj));
    }

    if (!isHome) {
      line_item_sections.push(<StyledHr key='hr' />);
    }

    if (line_item_details.hasOwnProperty('sub_total') && line_item_details.sub_total) {
      line_item_sections.push(renderNetFee(line_item_details.sub_total));
    }

    if (line_item_details.hasOwnProperty('tax')) {
      if (line_item_details.tax) {
        if (line_item_details.tax.length > 0) {
          line_item_sections.push(renderTaxes(line_item_details.tax));
        }
      }
    }

    if (
      line_item_details.hasOwnProperty('convenience_fee') &&
      line_item_details.convenience_fee &&
      parseFloat(line_item_details?.convenience_fee) > 0
    ) {
      line_item_sections.push(renderConvenienceFee(line_item_details?.convenience_fee));
    }

    if (line_item_sections.length === 1 && !isHome) {
      line_item_sections.unshift(
        <Paragraph key='costNotAvail'>{t('web_common:receipt.cost_details_not_avail')}</Paragraph>
      );
    }

    if (line_item_details.hasOwnProperty('total') && line_item_details.total) {
      let totalNumber = Number(line_item_details.total);
      if (is_payment_failed) {
        if (totalNumber > 0) {
          totalNumber *= -1;
        }
      }
      line_item_sections.push(
        <SpacedBetweenSectionContainer key='total'>
          <SectionTitle>{t('web_common:receipt.total')}</SectionTitle>
          <SectionTitle>{addCurrencySymbol(totalNumber.toString())}</SectionTitle>
        </SpacedBetweenSectionContainer>
      );
    }

    if (line_item_details.hasOwnProperty('converted_total') && line_item_details.converted_total) {
      const convertedTotalNumber = Number(line_item_details.converted_total);
      line_item_sections.push(
        <SpacedBetweenSectionContainer key='converted_total'>
          <SectionTitle>
            {getConvertedTotalLabel({
              driverCurrency: payment.user_currency,
              stationCurrency: payment.org_currency,
              conversionRate: Number(line_item_details.exchange_rate),
              t,
            })}
          </SectionTitle>
          <SectionTitle>
            {addCurrencySymbol(convertedTotalNumber.toString(), undefined, payment.user_currency)}
          </SectionTitle>
        </SpacedBetweenSectionContainer>
      );
    }

    return line_item_sections;
  }

  function renderEnergy(energy: Energy[]) {
    if (energy.length === 1 && !energy[0].energy_unit && !energy[0].units) {
      // for roaming sessions we don't have any details except line item cost
      return (
        <LineItemSection key='energy'>
          <SpacedBetweenSectionContainer>
            <SectionTitle>{t('web_common:receipt.receipt_details.energy')}</SectionTitle>
            {energy[0].line_item_cost && (
              <div aria-label={addCurrencySymbol(energy[0].line_item_cost.toString())}>
                {addCurrencySymbol(energy[0].line_item_cost.toString())}
              </div>
            )}
          </SpacedBetweenSectionContainer>
        </LineItemSection>
      );
    }
    const energy_items = energy.map((energy_item, index) => {
      return (
        // eslint-disable-next-line react/no-array-index-key
        <LineItemRow key={`energy_item${index}`}>
          <SpacedBetweenDiv>
            <div>
              {energy_item.units && (
                <LineItemRow>
                  {/* tslint:disable-next-line:max-line-length */}
                  {getEnergyLineItem(energy_item)}
                </LineItemRow>
              )}
              {energy_item.start_time_utc_timestamp && (
                <Text12>
                  {calcTime(energy_item.start_time_utc_timestamp)} -{' '}
                  {calcTime(energy_item.end_time_utc_timestamp)}
                </Text12>
              )}
            </div>
            {energy_item.line_item_cost && (
              <div aria-label={addCurrencySymbol(energy_item.line_item_cost.toString())}>
                {addCurrencySymbol(energy_item.line_item_cost.toString())}
              </div>
            )}
          </SpacedBetweenDiv>
        </LineItemRow>
      );
    });

    return (
      <LineItemSection key='energy'>
        <SectionTitle>{t('web_common:receipt.receipt_details.energy')}</SectionTitle>
        {energy_items}
      </LineItemSection>
    );
  }

  function renderPowerInterruption(interruptions: ChargeInterruption[]) {
    return (
      <LineItemSection key='interruption'>
        <SectionTitle>{t('web_common:receipt.receipt_details.power_interruption')}</SectionTitle>
        {interruptions.map((item, index) => {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <LineItemRow key={`interruption_${index}`}>
              <SpacedBetweenDiv>
                <div>
                  <Text12>
                    {calcInterruptionTime(item.start_time_utc)} -{' '}
                    {calcInterruptionTime(item.end_time_utc)}
                  </Text12>
                </div>
                <div> </div>
              </SpacedBetweenDiv>
            </LineItemRow>
          );
        })}
      </LineItemSection>
    );
  }

  function renderParking(parking: Parking[]) {
    return (
      <LineItemSection key='parking'>
        <SectionTitle>{t('web_common:receipt.receipt_details.parking')}</SectionTitle>
        {parking.map((parking_item, index) => {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <LineItemRow key={`parkingItem${index}`}>
              {parking_item.over_stay && renderOverstay(parking_item)}
              <SpacedBetweenDiv>
                <div>
                  <LineItemRow>
                    {parking_item.time} @ {addCurrencySymbol(parking_item.unit_price)}
                    {parking_item.unit_price_unit}
                  </LineItemRow>
                  <Text12>
                    {calcTime(parking_item.start_time_utc_timestamp)} -{' '}
                    {calcTime(parking_item.end_time_utc_timestamp)}
                  </Text12>
                </div>
                <div aria-label={addCurrencySymbol(parking_item.line_item_cost.toString())}>
                  {addCurrencySymbol(parking_item.line_item_cost.toString())}
                </div>
              </SpacedBetweenDiv>
            </LineItemRow>
          );
        })}
      </LineItemSection>
    );
  }

  function renderFlatFees(flat_fee: FlatFee) {
    return (
      <LineItemSection key='flatFee'>
        <SpacedBetweenSectionContainer>
          <SectionTitle>{t('web_common:receipt.receipt_details.flat_fee')}</SectionTitle>
          <div aria-label={addCurrencySymbol(flat_fee.line_item_cost.toString())}>
            {addCurrencySymbol(flat_fee.line_item_cost.toString())}
          </div>
        </SpacedBetweenSectionContainer>
      </LineItemSection>
    );
  }

  function renderTaxes(taxes: Tax[]) {
    return (
      <LineItemSection key='taxes'>
        {taxes.map((tax, index) => {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <LineItemRow key={`tax${index}`}>
              <FlexEndDiv>
                <div>
                  {tax.tax_name} {addPercentSign(tax.tax_percent_original)}
                </div>
                <TaxValue aria-label={addCurrencySymbol(tax.tax_val.toString())}>
                  {addCurrencySymbol(tax.tax_val.toString())}
                </TaxValue>
              </FlexEndDiv>
            </LineItemRow>
          );
        })}
      </LineItemSection>
    );
  }

  function renderNetFee(net_fee: string) {
    return (
      <LineItemSection key='sub_total'>
        <LineItemRow key='subtotal_net'>
          <FlexEndDiv>
            <div>{t('web_common:receipt.subtotal_net')}</div>
            <TaxValue aria-label={addCurrencySymbol(net_fee)}>
              {addCurrencySymbol(net_fee)}
            </TaxValue>
          </FlexEndDiv>
        </LineItemRow>
      </LineItemSection>
    );
  }

  function renderConvenienceFee(convenience_fee: string) {
    return (
      <LineItemSection key='convenience'>
        <LineItemRow key='convenience_fee'>
          <FlexEndDiv>
            <div>{t('web_common:receipt.convenience_fee')}</div>
            <TaxValue aria-label={addCurrencySymbol(convenience_fee)}>
              {addCurrencySymbol(convenience_fee)}
            </TaxValue>
          </FlexEndDiv>
        </LineItemRow>
      </LineItemSection>
    );
  }

  const renderMaxFees = (maxFee: Maxadj) => {
    return (
      <LineItemSection key='maxFee'>
        <SectionTitle>{t('web_common:receipt.receipt_details.max_adj')}</SectionTitle>
        <SpacedBetweenSectionContainer>
          <div>
            {addCurrencySymbol(maxFee.subsess_max.toString())}/{maxFee.time}
          </div>
          <div aria-label={addCurrencySymbol(maxFee.total_adj.toString())}>
            {addCurrencySymbol(maxFee.total_adj.toString())}
          </div>
        </SpacedBetweenSectionContainer>
      </LineItemSection>
    );
  };

  const renderMinFees = (minFee: Minadj) => {
    return (
      <LineItemSection key='minFee'>
        <SectionTitle>{t('web_common:receipt.receipt_details.min_adj')}</SectionTitle>
        <SpacedBetweenSectionContainer>
          <div>
            {addCurrencySymbol(minFee.subsess_min.toString())}/{minFee.time}
          </div>
          <div aria-label={addCurrencySymbol(minFee.total_adj.toString())}>
            {addCurrencySymbol(minFee.total_adj.toString())}
          </div>
        </SpacedBetweenSectionContainer>
      </LineItemSection>
    );
  };

  const renderOverstay = (parkingItem: Parking) => {
    if (parkingItem.over_stay_type === 'duration') {
      return (
        <OverstayText12>
          {t('web_common:receipt.overstay_time_limit_exceeded', {
            minutes: parkingItem.over_stay_dur_time,
          })}
        </OverstayText12>
      );
    }
    return (
      <OverstayText12>
        {t('web_common:receipt.overstay_fully_charged', {
          time: calcTime(parkingItem.start_time_utc_timestamp),
        })}
      </OverstayText12>
    );
  };

  const localizeDecimal = (value: string, minFractionDigits = 0) => {
    return new Intl.NumberFormat(locale, {
      minimumFractionDigits: minFractionDigits,
      maximumFractionDigits: 4,
    }).format(parseFloat(value));
  };

  const addCurrencySymbol = (
    amount: string,
    maximumFractionDigits = 2,
    currencyCodeOveride?: string
  ) => {
    if (amount === undefined || amount === null) {
      return '';
    }
    return new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currencyCodeOveride || currencyCode,
      maximumFractionDigits,
    }).format(parseFloat(amount));
  };

  const getTransactionStartedByString = ({ type, source }: Payment) => {
    if (!type || !source || isHome) {
      return '';
    }
    switch (type) {
      case 'Mobile Digital Card':
        return t('web_common:receipt.transaction.started_by_mobile_app');
      case 'RFID':
        return t('web_common:receipt.transaction.started_by_RFID', { rfid_serial_number: source });
      default:
        return t('web_common:receipt.transaction.started_by_credit_card', { cc_last_4: source });
    }
  };

  function calcBillingTime(session_end_epoch_time: string, session_start_epoch_time: string) {
    const session_end_epoch_time_local = Number(session_end_epoch_time) + stationTimeOffset * 1000;
    const session_start_epoch_time_local =
      Number(session_start_epoch_time) + stationTimeOffset * 1000;

    const startDate = new Date(session_start_epoch_time_local);
    const endDate = new Date(session_end_epoch_time_local);

    let result = `${startDate.toLocaleString(locale, {
      timeZone: 'UTC',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    })} - `;
    if (endDate.getUTCFullYear() === startDate.getUTCFullYear()) {
      if (endDate.getUTCMonth() === startDate.getUTCMonth()) {
        if (endDate.getUTCDate() === startDate.getUTCDate()) {
          result += endDate.toLocaleString(locale, {
            timeZone: 'UTC',
            hour: 'numeric',
            minute: 'numeric',
          });
        } else {
          result += endDate.toLocaleString(locale, {
            timeZone: 'UTC',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
          });
        }
      } else {
        result += endDate.toLocaleString(locale, {
          timeZone: 'UTC',
          month: 'long',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
        });
      }
    } else {
      result += endDate.toLocaleString(locale, {
        timeZone: 'UTC',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      });
    }

    return result;
  }

  function calcTotalEnergy() {
    return formatkWh(t, locale, true, chargingSessionData?.line_item_details.energy_dispensed);
  }

  function calcMeterRange() {
    return `${formatkWh(
      t,
      locale,
      false,
      chargingSessionData?.session_start_mtr_value
    )} - ${formatkWh(t, locale, false, chargingSessionData?.session_end_mtr_value)}`;
  }

  /**
   * function returns a localized time string based on a timestamp and an offset.
   */
  function calcTime(timeStamp: string) {
    let localTimeStamp = Number(timeStamp);
    localTimeStamp += stationTimeOffset;
    const timeStampDate = new Date(localTimeStamp * 1000);
    return timeStampDate.toLocaleTimeString(locale, {
      timeZone: 'UTC',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    });
  }

  function calcInterruptionTime(timeStamp: string) {
    const timeStampDate = new Date(Number(timeStamp) + stationTimeOffset * 1000);
    return timeStampDate.toLocaleTimeString(locale, {
      timeZone: 'UTC',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    });
  }

  function getLocaleDate(timeStamp: string) {
    const timeStampDate = new Date(Number(timeStamp) + stationTimeOffset * 1000);
    return timeStampDate.toLocaleString(locale, {
      timeZone: 'UTC',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  }

  function addPercentSign(percentNumber: string) {
    const percent = Number(percentNumber) / 100;
    return new Intl.NumberFormat(locale, {
      style: 'percent',
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    }).format(percent);
  }

  function getAddress() {
    let addressObj = null;
    if (chargingSessionData?.driver) {
      addressObj = chargingSessionData?.driver.address;
      addressObj.title = chargingSessionData?.driver.name;
    } else if (chargingSessionData?.vehicle) {
      addressObj = chargingSessionData?.vehicle.address;
      addressObj.title = chargingSessionData?.vehicle.name;
    }
    return addressObj;
  }

  function renderVATID() {
    switch (chargingSessionData?.cp_vat_id) {
      case undefined:
        return null;
      case '':
        return <Paragraph>{t('web_common:receipt.vat_id_pending')}</Paragraph>;
      default:
        return (
          <Paragraph>
            {t('web_common:receipt.vat_id', { vat_id: chargingSessionData?.cp_vat_id })}
          </Paragraph>
        );
    }
  }

  function renderEMVData() {
    if (!emvData) {
      return null;
    }
    return (
      <>
        <SectionContainer>
          {emvData.transactionType && (
            <SpacedBetweenDiv>
              <Text12Flex>{t('web_common:receipt.emvData.transaction_type')}</Text12Flex>
              <Text12Flex>{emvData.transactionType}</Text12Flex>
            </SpacedBetweenDiv>
          )}
          {emvData.cardBrand && (
            <SpacedBetweenDiv>
              <Text12Flex>{t('web_common:receipt.emvData.card_brand')}</Text12Flex>
              <Text12Flex>{emvData.cardBrand}</Text12Flex>
            </SpacedBetweenDiv>
          )}
          <SpacedBetweenDiv>
            <Text12Flex>{t('web_common:receipt.emvData.account_number')}</Text12Flex>
            <Text12Flex>{chargingSessionData?.payment.source}</Text12Flex>
          </SpacedBetweenDiv>
          <SpacedBetweenDiv>
            <Text12Flex>{t('web_common:receipt.emvData.entry_legend')}</Text12Flex>
            <Text12Flex>{emvData.entryLegend}</Text12Flex>
          </SpacedBetweenDiv>
          <SpacedBetweenDiv>
            <Text12Flex>{t('web_common:receipt.emvData.entry_mode')}</Text12Flex>
            <Text12Flex>{emvData.entryMode}</Text12Flex>
          </SpacedBetweenDiv>
          <SpacedBetweenDiv>
            <Text12Flex>{t('web_common:receipt.emvData.currency')}</Text12Flex>
            <Text12Flex>{emvData.currencyCode}</Text12Flex>
          </SpacedBetweenDiv>
          <SpacedBetweenDiv>
            <Text12Flex>{t('web_common:receipt.emvData.transaction_rec_id')}</Text12Flex>
            <Text12Flex>{emvData.userPaymentLogId}</Text12Flex>
          </SpacedBetweenDiv>
          <SpacedBetweenDiv>
            <Text12Flex>{t('web_common:receipt.emvData.ap_code')}</Text12Flex>
            <Text12Flex>{emvData.apCode}</Text12Flex>
          </SpacedBetweenDiv>
          <SpacedBetweenDiv>
            <Text12Flex>{t('web_common:receipt.emvData.aid')}</Text12Flex>
            <Text12Flex>{emvData.aid}</Text12Flex>
          </SpacedBetweenDiv>
          <SpacedBetweenDiv>
            <Text12Flex>{t('web_common:receipt.emvData.aid_name')}</Text12Flex>
            <Text12Flex>{emvData.aidName}</Text12Flex>
          </SpacedBetweenDiv>
          <SpacedBetweenDiv>
            <Text12Flex>{t('web_common:receipt.emvData.ac')}</Text12Flex>
            <Text12Flex>{emvData.ac}</Text12Flex>
          </SpacedBetweenDiv>
        </SectionContainer>
        <SectionContainer>
          <TextCenter>
            {emvData.declineCode !== '0' && (
              <Paragraph14Upper>
                {t('web_common:receipt.emvData.transaction_declined')}
              </Paragraph14Upper>
            )}
            <Paragraph14>{t('web_common:receipt.emvData.customer_copy')}</Paragraph14>
            <Paragraph>{t('web_common:receipt.emvData.no_signature_required')}</Paragraph>
          </TextCenter>
          <Paragraph>{t('web_common:receipt.emvData.refund_policy')}</Paragraph>
        </SectionContainer>
      </>
    );
  }

  return (
    <TextRichBlack className={className}>
      <PrintSection ref={printRef} className='print-section'>
        <TextCenter>
          <Paragraph16>{t('web_common:receipt.charging_services')}</Paragraph16>
          <Paragraph14>
            {chargingSessionData.showInvoice === true
              ? t('web_common:receipt.invoice_date')
              : t('web_common:receipt.receipt_date')}{' '}
            {getLocaleDate(chargingSessionData.session_end_epoch_time)}
          </Paragraph14>
        </TextCenter>
        <SpacedBetweenSectionContainer>
          <AddressDiv>
            {address && (
              <>
                {address.title && <Text14>{address.title}</Text14>}
                {address.address1 && <Text14>{address.address1}</Text14>}
                {address.address2 && <Text14>{address.address2}</Text14>}
                <Text14>
                  {address.city && <>{address.city},</>} {address.state} {address.zipcode}{' '}
                  {address.country}
                </Text14>
              </>
            )}
          </AddressDiv>
          <RightAlignDiv>
            {chargingSessionData.vehicle && chargingSessionData.vehicle.license_plate ? (
              <>
                <Text14NoWrap>
                  {isDriver ? t('web_common:receipt.receipt_details.license_number') : ''}
                </Text14NoWrap>
                <Text14NoWrap>
                  {isDriver ? chargingSessionData.vehicle.license_plate : ''}
                </Text14NoWrap>
              </>
            ) : (
              <>
                <Text14NoWrap>
                  {chargingSessionData.invoiceNumberExists === true
                    ? t('web_common:receipt.receipt_details.invoice_number')
                    : t('web_common:receipt.receipt_details.receipt_number')}
                </Text14NoWrap>
                <Text14NoWrap>{chargingSessionData.transaction_id}</Text14NoWrap>
              </>
            )}
          </RightAlignDiv>
        </SpacedBetweenSectionContainer>
        <SectionContainer>
          <SectionTitle>{t('web_common:receipt.station_id')}</SectionTitle>
          <SpacedBetweenDiv>
            <SectionTitle>
              {chargingSessionData.station.name
                ? chargingSessionData.station.name
                : t('web_common:receipt.not_available')}
            </SectionTitle>
            <StationCity>{chargingSessionData.station.address.city}</StationCity>
          </SpacedBetweenDiv>
          {chargingSessionData.station.max_energy && (
            <LineItemRow>
              {/* tslint:disable-next-line:max-line-length */}
              {localizeDecimal(chargingSessionData.station.max_energy.toString(), 1)}{' '}
              {t('web_common:receipt.units.kW')}{' '}
              {POWER_TYPE[chargingSessionData.station.power_type]
                ? POWER_TYPE[chargingSessionData.station.power_type]
                : chargingSessionData.station.power_type}
            </LineItemRow>
          )}
          <Text14>{t('web_common:receipt.charging_duration')}</Text14>
          <Text14>{billingTime}</Text14>
          <Text14>{t('web_common:receipt.total_energy')}</Text14>
          <Text14>{totalEnergy}</Text14>
        </SectionContainer>
        {renderLineItemDetails(
          chargingSessionData.line_item_details,
          chargingSessionData.charge_interruptions,
          chargingSessionData.is_payment_failed,
          chargingSessionData.payment
        )}
        {chargingSessionData.is_payment_failed && (
          <FailedTransactionParagraph>
            {t('web_common:receipt.failed_transaction')}
          </FailedTransactionParagraph>
        )}
        <SectionContainer>
          {chargingSessionData.billed_to && (
            <Paragraph14>
              {t('web_common:receipt.billed_to', {
                companyName: chargingSessionData.billed_to,
                interpolation: { escapeValue: false },
              })}
            </Paragraph14>
          )}
          {!chargingSessionData.isRoaming && !isHome && (
            <Paragraph14>
              {t('web_common:receipt.price_set_by', {
                company_name: chargingSessionData.company ? chargingSessionData.company.name : '',
                interpolation: { escapeValue: false },
              })}
            </Paragraph14>
          )}
          {chargingSessionData.payment.type && chargingSessionData.payment.source && (
            <Paragraph>{getTransactionStartedByString(chargingSessionData.payment)}</Paragraph>
          )}
          <Paragraph>
            {t('web_common:receipt.station_address')}
            <br />
            {chargingSessionData.station.address.address1}
            <br />
            {chargingSessionData.station.address.address2 && (
              <>
                {chargingSessionData.station.address.address2}
                <br />
              </>
            )}
            {/* tslint:disable-next-line:max-line-length */}
            {chargingSessionData.station.address.city}, {chargingSessionData.station.address.state}{' '}
            {chargingSessionData.station.address.zipcode}{' '}
            {chargingSessionData.station.address.country}
          </Paragraph>
          <Paragraph>
            {t('web_common:receipt.meter_range')}
            <br />
            {meter_range}
          </Paragraph>
          {renderEMVData()}
          {chargingSessionData.issued_by && (
            <Paragraph>
              {chargingSessionData.issued_by.name}
              <br />
              {chargingSessionData.issued_by.address}
            </Paragraph>
          )}
          {renderVATID()}
          {!hideButtons && chargingSessionData.PTB_enabled_station && (
            <PTBFooter
              station={{
                ...chargingSessionData.PTB_enabled_station,
                ...{
                  vehicle_charge_id: chargingSessionData.vehicle_charge_id,
                  device_id: chargingSessionData.station.id,
                },
              }}
              nosServerEndpoint={nosServerEndpoint}
              accountEndPoint={accountEndPoint}
              corporateFAQUrl={corporateFAQUrl}
              Trans={Trans}
              t={t}
              isLoggedIn={isLoggedIn}
            />
          )}
          <Paragraph>
            <Trans
              i18nKey='web_common:receipt.questions'
              components={{
                // eslint-disable-next-line jsx-a11y/anchor-has-content, jsx-a11y/control-has-associated-label
                btn: <KitLink t={t} href={corpSupportUrl} />,
              }}
              values={{
                supportLink: corpSupportUrl.substring(8),
              }}
            />
          </Paragraph>
        </SectionContainer>
      </PrintSection>
      {!hideButtons && !hidePrint && (
        <PrintButtonContainer>
          <KitButton
            variant={printButtonVariant}
            onClick={() => {
              if (printReceipt) {
                printReceipt();
              }
            }}
          >
            {t('web_common:receipt.print')}
          </KitButton>
        </PrintButtonContainer>
      )}
    </TextRichBlack>
  );
};

export default ReceiptDetails;
