import {
  FC,
  FormEvent,
  useEffect,
  useState,
  useCallback,
  useRef,
  ReactNode,
  ChangeEvent,
} from 'react';
import styled from 'styled-components';
import {
  KitFlexRowSpaced,
  KitButton,
  ThemeConstants as constants,
  KitForm,
  KitSpinner,
  KitRadio,
  KitRadioGroup,
  KitButtonBar,
  KitFlexRow,
} from '@chargepoint/cp-toolkit';
import { usePaymentInputs } from 'react-payment-inputs';
import { TranslateFunc } from '../../types';

// eslint-disable-next-line no-shadow
enum CreditCardType {
  MASTERCARD,
  VISA,
  AMEX,
  DISCOVER,
}

export interface PaymentFormProps {
  onAuthorizeCard(
    cardNumber: number,
    expirationMonth: number,
    expirationYear: number,
    cvc: number,
    zip: string
  ): void;
  onCancel: (refresh?: boolean) => void;
  onProcessPayPal(): void;
  isLoading: boolean;
  userZipCode: string;
  className?: string;
  t: TranslateFunc;
  defaultToPayPal?: boolean;
}

const CardInfoLine = styled(KitFlexRowSpaced)`
  > div:not(:last-of-type) {
    margin-right: ${constants.spacing.absolute.m}px;
  }
`;

const CreditCardFieldSet = styled(KitForm.FieldSet)`
  > legend {
    overflow: hidden;
    clip-path: inset(50%);
  }
  label {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 180px;
  }
  @media (max-width: ${constants.breakpoints.sm}px) {
    label {
      max-width: 120px;
    }
    ${CardInfoLine} {
      > div:not(:last-of-type) {
        margin-right: ${constants.spacing.absolute.s}px;
      }
    }
  }
`;

const PayWithPayPal = styled.img`
  height: ${constants.fontSize.base}rem;
  margin-top: 2px;
`;

const NumberRow = styled(KitFlexRow)`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 54px;
  grid-column-gap: ${constants.spacing.absolute.s}px;
  align-items: end;

  img {
    width: 100%;
  }
`;

// eslint-disable-next-line no-shadow
enum PaymentType {
  cc,
  paypal,
}

const PaymentForm: FC<PaymentFormProps> = ({
  onAuthorizeCard,
  onProcessPayPal,
  onCancel,
  isLoading,
  userZipCode,
  className,
  t,
  defaultToPayPal,
}: PaymentFormProps) => {
  const { getCardNumberProps, getExpiryDateProps, getCVCProps, getZIPProps, meta } =
    usePaymentInputs({
      errorMessages: {
        emptyCardNumber: t('web_common:payment_form.emptyCardNumber'),
        emptyExpiryDate: t('web_common:payment_form.emptyExpiryDate'),
        emptyCVC: t('web_common:payment_form.emptyCVC'),
        emptyZIP: t('web_common:payment_form.emptyZIP'),
        invalidCardNumber: t('web_common:payment_form.invalidCardNumber'),
        invalidExpiryDate: t('web_common:payment_form.invalidExpiryDate'),
        invalidCVC: t('web_common:payment_form.invalidCVC'),
        monthOutOfRange: t('web_common:payment_form.monthOutOfRange'),
        yearOutOfRange: t('web_common:payment_form.yearOutOfRange'),
        dateOutOfRange: t('web_common:payment_form.dateOutOfRange'),
      },
    });
  const genericLogo = '/images/ic_credit_generic.png';
  const { erroredInputs, touchedInputs } = meta;
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const [errorIDs, setErrorIDs] = useState<string | undefined>(undefined);
  const [step, setStep] = useState(1);
  const [paymentType, setPaymentType] = useState<PaymentType>(
    defaultToPayPal ? PaymentType.paypal : PaymentType.cc
  );
  const [ccType, setCCType] = useState<CreditCardType | undefined>(undefined);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [ccImage, setCCImage] = useState<string>(genericLogo);
  const [ccAlt, setCCAlt] = useState<string>('');
  const radioCCRef = useRef<HTMLInputElement>(null);
  const radioPayPalRef = useRef<HTMLInputElement>(null);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function cleanUpProps(props: any) {
    // clean up react-payment-inputs to use cp-toolkit states
    Reflect.deleteProperty(props, 'onBlur');
    Reflect.deleteProperty(props, 'placeholder');
    Reflect.deleteProperty(props, 'onFocus');

    if (Reflect.get(props, 'name') === 'zip') {
      Reflect.deleteProperty(props, 'maxLength');
      Reflect.deleteProperty(props, 'onKeyPress');
    }
    return props;
  }

  function onNext(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    if (step === 1) {
      if (paymentType === PaymentType.cc) {
        setStep(2);
      } else {
        onProcessPayPal();
      }
    } else {
      const form = e.currentTarget;
      setIsSubmit(true);

      if (typeof meta.error === 'undefined') {
        onAuthorizeCard(
          form.cardNumber.value.replaceAll(' ', ''),
          form.expiryDate.value.split(' / ')[0],
          form.expiryDate.value.split(' / ')[1],
          form.cvc.value,
          form.zip.value
        );
      }
    }
  }

  const showError = useCallback(
    (field: string) => {
      return erroredInputs[field] && (isSubmit || touchedInputs[field]);
    },
    [erroredInputs, touchedInputs, isSubmit]
  );

  useEffect(() => {
    const errorDivIDs: string[] = [];
    ['cardNumber', 'expiryDate', 'cvc', 'zip'].forEach((field) => {
      if (showError(field)) {
        errorDivIDs.push(`${field}Info`);
      }
    });

    setErrorIDs(errorDivIDs.length ? errorDivIDs.join(' ') : undefined);
  }, [erroredInputs, touchedInputs, showError]);

  useEffect(() => {
    if (step === 1) {
      if (paymentType === PaymentType.cc) {
        radioCCRef?.current?.focus();
      } else {
        radioPayPalRef?.current?.focus();
      }
    }
  }, [step, radioCCRef, radioPayPalRef, paymentType]);

  const getCCType = (s: string): CreditCardType | undefined => {
    if (s.match(new RegExp('^(?:5[1-6]|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)'))) {
      return CreditCardType.MASTERCARD;
    }

    if (s.match(new RegExp('^4'))) {
      return CreditCardType.VISA;
    }

    if (s.match(new RegExp('^3[47]'))) {
      return CreditCardType.AMEX;
    }

    if (s.match(new RegExp('^6(?:011|5[0-9]{2})'))) {
      return CreditCardType.DISCOVER;
    }

    return undefined;
  };

  const onChangeCardNumber = (event: ChangeEvent<HTMLInputElement>) => {
    setCCType(getCCType(event.target.value));
  };

  useEffect(() => {
    switch (ccType) {
      case CreditCardType.MASTERCARD:
        setCCImage('/images/mc_symbol.svg');
        setCCAlt('Mastercard');
        break;
      case CreditCardType.AMEX:
        setCCImage('/images/ic_credit_amex.png');
        setCCAlt('American Express');
        break;
      case CreditCardType.VISA:
        setCCImage('/images/ic_credit_visa.png');
        setCCAlt('VISA');
        break;
      case CreditCardType.DISCOVER:
        setCCImage('/images/ic_credit_discover_cp.png');
        setCCAlt('Discover');
        break;
      default:
        setCCImage(genericLogo);
        setCCAlt('');
    }
  }, [ccType]);

  const renderStep1 = (): ReactNode => {
    return (
      <KitForm.Group>
        <KitRadioGroup required name='radio'>
          <KitRadio
            name='paymentType'
            value={PaymentType.cc}
            label={t('web_common:payment_form.radios.creditCard')}
            onChange={() => {
              setPaymentType(PaymentType.cc);
            }}
            checked={paymentType === PaymentType.cc}
            ref={radioCCRef}
          />
          <KitRadio
            name='paymentType'
            value={PaymentType.paypal}
            onChange={() => {
              setPaymentType(PaymentType.paypal);
            }}
            checked={paymentType === PaymentType.paypal}
            ref={radioPayPalRef}
          >
            <PayWithPayPal src='/images/paypallogo.png' alt='PayPal' />
            <div className='cp-form-label--description'>
              {t('web_common:payment_form.radios.paypal_help')}
            </div>
          </KitRadio>
        </KitRadioGroup>
      </KitForm.Group>
    );
  };

  const renderStep2 = (): ReactNode => {
    return (
      <>
        <p aria-live='polite'>{t('web_common:payment_form.credit_card_help')}</p>
        <CreditCardFieldSet legend={t('web_common:payment_form.credit_card_information')}>
          <KitForm.Group>
            <NumberRow>
              <div>
                <KitForm.Input
                  id='cardNumber'
                  name='cardNumber'
                  required
                  label={t('web_common:payment_form.card_number')}
                  data-qa-id='cc_number'
                  {...cleanUpProps(getCardNumberProps({ onChange: onChangeCardNumber }))}
                  isError={showError('cardNumber')}
                  infoMessage={showError('cardNumber') ? erroredInputs.cardNumber : undefined}
                  autoFocus
                />
              </div>
              {ccImage && <img src={ccImage} alt={ccAlt} />}
            </NumberRow>
          </KitForm.Group>
          <CardInfoLine>
            <KitForm.Group>
              <KitForm.Input
                id='expiryDate'
                name='expiryDate'
                required
                label={t('web_common:payment_form.cc_expire_date')}
                data-qa-id='cc_expire_date'
                {...cleanUpProps(getExpiryDateProps())}
                isError={showError('expiryDate')}
                infoMessage={showError('expiryDate') ? erroredInputs.expiryDate : undefined}
              />
            </KitForm.Group>
            <KitForm.Group>
              <KitForm.Input
                id='cvc'
                name='cvc'
                required
                label={t('web_common:payment_form.cc_cvv')}
                data-qa-id='cc_cvv'
                {...cleanUpProps(getCVCProps())}
                isError={showError('cvc')}
                infoMessage={showError('cvc') ? erroredInputs.cvc : undefined}
              />
            </KitForm.Group>
            <KitForm.Group>
              <KitForm.Input
                id='zip'
                name='zip'
                required
                label={t('web_common:payment_form.zip_postal_code')}
                data-qa-id='cc_zip_postal_code'
                {...cleanUpProps(getZIPProps({ type: 'text', defaultValue: userZipCode }))}
                isError={showError('zip')}
                infoMessage={showError('zip') ? erroredInputs.zip : undefined}
              />
            </KitForm.Group>
          </CardInfoLine>
        </CreditCardFieldSet>
      </>
    );
  };

  const renderStep = (): ReactNode => {
    return (
      <>
        <KitSpinner size='s' withOverlay show={isLoading} />
        {step === 1 ? renderStep1() : renderStep2()}
      </>
    );
  };

  return (
    <KitForm noValidate onSubmit={onNext} className={className}>
      <>
        {renderStep()}
        <KitButtonBar
          primary={
            <KitButton
              type='submit'
              data-qa-id='payment_save_button'
              aria-disabled={step === 2 && !!errorIDs}
              aria-describedby={step === 2 ? errorIDs : undefined}
            >
              {step === 1
                ? t('web_common:payment_form.next_button')
                : (t('web_common:payment_form.save_button') as string)}
            </KitButton>
          }
          secondary={
            <KitButton variant='secondary' type='button' onClick={() => onCancel(false)}>
              {t('web_common:payment_form.cancel_button')}
            </KitButton>
          }
        />
      </>
    </KitForm>
  );
};

export default PaymentForm;
